.tfi-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tfi-blink-logo {
  animation: blink 2s infinite;
}

@keyframes blink {
  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

.avatar {
  --bs-avatar-width: 32px;
  --bs-avatar-bg: #44546f;
  position: relative;
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  line-height: 1;
  font-size: 14px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  background-color: #091e42;
}

/* Timeline holder */
ul.timeline {
  list-style-type: none;
  position: relative;
  padding-left: 1.5rem;
}

/* Timeline vertical line */
ul.timeline:before {
  content: ' ';
  background: #fff;
  display: inline-block;
  position: absolute;
  left: 16px;
  width: 4px;
  height: 100%;
  z-index: 1;
  border-radius: 1rem;
}

li.timeline-item {
  margin: 20px 0;
}

/* Timeline item arrow */
.timeline-arrow {
  border-top: 0.5rem solid transparent;
  border-right: 0.5rem solid #fff;
  border-bottom: 0.5rem solid transparent;
  display: block;
  position: absolute;
  left: -5px;
}

/* Timeline item circle marker */
li.timeline-item::before {
  content: ' ';
  background: #ddd;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #fff;
  left: -22px;
  width: 14px;
  height: 14px;
  z-index: 1;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.container-draggs::-webkit-scrollbar {
  height: 10px;
}

/* Track */
.container-draggs::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.container-draggs::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.container-draggs::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.drop-scrollbar::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.drop-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.drop-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.drop-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* STEPPERSSSSSS */

.steps .step {
  display: block;
  width: 100%;
  margin-bottom: 35px;
  text-align: center;
}

.steps .step .step-icon-wrap {
  display: block;
  position: relative;
  width: 100%;
  height: 24px;
  text-align: center;
}

.steps .step .step-icon-wrap::before,
.steps .step .step-icon-wrap::after {
  display: block;
  position: absolute;
  top: 50%;
  width: 50%;
  height: 3px;
  margin-top: -1px;
  background-color: #e1e7ec;
  content: '';
  z-index: 1;
}

.steps .step .step-icon-wrap::before {
  left: 0;
}

.steps .step .step-icon-wrap::after {
  right: 0;
}

.steps .step .step-icon {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
  border: 1px solid #e1e7ec;
  border-radius: 50%;
  background-color: #f5f5f5;
  color: #374250;
  font-size: 18px;
  line-height: 22px;
  z-index: 2;
}

.steps .step .step-title {
  margin-top: 16px;
  margin-bottom: 0;
  color: #606975;
  font-size: 14px;
  font-weight: 500;
}

.steps .step:first-child .step-icon-wrap::before {
  display: none;
}

.steps .step:last-child .step-icon-wrap::after {
  display: none;
}

.steps .step.completed .step-icon-wrap::before,
.steps .step.completed .step-icon-wrap::after {
  background-color: #0da9ef;
}

.steps .step.completed .step-icon {
  border-color: #0da9ef;
  background-color: #0da9ef;
  color: #fff;
}

@media (max-width: 576px) {
  .flex-sm-nowrap .step .step-icon-wrap::before,
  .flex-sm-nowrap .step .step-icon-wrap::after {
    display: none;
  }
}

@media (max-width: 768px) {
  .flex-md-nowrap .step .step-icon-wrap::before,
  .flex-md-nowrap .step .step-icon-wrap::after {
    display: none;
  }
}

@media (max-width: 991px) {
  .flex-lg-nowrap .step .step-icon-wrap::before,
  .flex-lg-nowrap .step .step-icon-wrap::after {
    display: none;
  }
}

@media (max-width: 1200px) {
  .flex-xl-nowrap .step .step-icon-wrap::before,
  .flex-xl-nowrap .step .step-icon-wrap::after {
    display: none;
  }
}

.bg-faded,
.bg-secondary {
  background-color: #f5f5f5 !important;
}
.z-999 {
  z-index: 99999;
}

@media (min-width: 992px) {
  .d-none-search-header {
    display: none !important;
  }
}

.info-idx {
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin: 9px auto;
  transition: all;
}
.progressbar-list::after {
  content: '';
  background-color: rgb(155, 155, 155);
  padding: 0px 0px;
  position: absolute;
  top: 14px;
  left: -50%;
  width: 100%;
  height: 2px;
  margin: 9px auto;
  z-index: -1;
  transition: all 0.8s;
}
.progressbar-list.active::after {
  background-color: #3e97ff;
}
.progressbar-list:first-child::after {
  content: none;
}

.progressbar-list {
  color: #6f787d;
}
.active {
  color: #000;
}

@media all and (-webkit-min-device-pixel-ratio: 1.5),
  all and (-o-min-device-pixel-ratio: 3 / 2),
  all and (min--moz-device-pixel-ratio: 1.5),
  all and (min-device-pixel-ratio: 1.5) {
  .timeline-tfi {
    background-size: 2px auto;
  }
}

.timeline-tfi::after {
  display: block;
  clear: both;
  content: '';
}

.timeline-marker-tfi {
  clear: both;
  background: #fff;
}

.timeline-marker-tfi h1,
.timeline-marker-tfi h2,
.timeline-marker-tfi h3,
.timeline-marker-tfi h4,
.timeline-marker-tfi h5 {
  margin: 0 0 5px;
}

.timeline-marker-tfi:after,
.timeline-marker-tfi:before {
  width: 12px;
  height: 12px;
  background: #1b1b1b;
  margin: 0 auto;
  border-radius: 100%;
  display: block;
  content: ' ';
}

.timeline-marker-tfi:before {
  display: none;
}

.timeline-marker-tfi.timeline-marker-bottom-tfi h1,
.timeline-marker-tfi.timeline-marker-bottom-tfi h2,
.timeline-marker-tfi.timeline-marker-bottom-tfi h3,
.timeline-marker-tfi.timeline-marker-bottom-tfi h4,
.timeline-marker-tfi.timeline-marker-bottom-tfi h5 {
  margin: 5px 0 0;
}

.timeline-marker-tfi.timeline-marker-bottom-tfi:before {
  display: block;
}

.timeline-marker-tfi.timeline-marker-bottom-tfi:after {
  display: none;
}

.timeline-marker-tfi.timeline-marker-middle-tfi {
  margin-top: 20px;
  margin-bottom: 20px;
}

.timeline-breaker-tfi {
  background: #181836;
  color: #fff;
  font-weight: 600;
  border-radius: 2px;
  margin: 0 auto;
  text-align: center;
  padding: 0.6em;
  line-height: 1;
  display: block;
  width: 100%;
  // max-width: 15em;
  clear: both;
}

.timeline-breaker-tfi::after {
  display: block;
  clear: both;
  // content: '';
}

.timeline-breaker-tfi a {
  color: #fff;
}

.timeline-breaker-tfi a:hover {
  color: #eee;
}

.timeline-breaker-tfi:after,
.timeline-breaker-tfi:before {
  top: 100%;
  border: solid transparent;
  // content: ' ';
  height: 1px;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.timeline-breaker-tfi:after {
  border-top-color: #1b1b1b;
  border-width: 10px;
  left: 50%;
  margin-left: -10px;
}

.timeline-breaker-tfi:before {
  border-top-color: rgba(0, 0, 0, 0.01);
  border-width: 11px;
  left: 50%;
  margin-left: -11px;
}

.timeline-breaker-tfi.timeline-breaker-bottom-tfi,
.timeline-breaker-tfi.timeline-breaker-middle-tfi {
  margin-top: 40px;
  margin-bottom: 20px;
  clear: both !important;
}

.timeline-breaker-tfi.timeline-breaker-bottom-tfi {
  margin-bottom: 0;
}

.timeline-breaker-tfi.timeline-breaker-bottom-tfi:after,
.timeline-breaker-tfi.timeline-breaker-bottom-tfi:before {
  top: -10px;
  border-top: none;
}

.timeline-breaker-tfi.timeline-breaker-bottom-tfi:after {
  border-bottom-color: #1b1b1b;
}

.timeline-breaker-tfi.timeline-breaker-bottom-tfi:before {
  border-bottom-color: rgba(0, 0, 0, 0.01);
}

.timeline-item-tfi.timeline-item-first-tfi {
  margin-top: 20px !important;
}

.timeline-item-tfi.timeline-item-last-tfi {
  margin-bottom: 20px !important;
}

.timeline-item-tfi {
  float: none;
  left: auto;
  right: auto;
  width: 100%;
  padding: 15px;
  margin: 60px auto 0;
  background: #f6f6f6;
  border-radius: 2px;
  position: relative;
  border: 1px solid #f2f2f2;
  border-bottom: 3px solid #8998eb;
  text-align: left;
}

.timeline-item-tfi::after {
  display: block;
  clear: both;
  content: '';
}

.timeline-item-tfi:after,
.timeline-item-tfi:before {
  top: -20px;
  right: 50%;
  left: 50%;
  position: absolute;
  pointer-events: none;
  display: block;
  font-size: 30px;
  height: 30px;
  line-height: 30px;
  width: 30px;
  text-align: center;
  margin-top: 0;
  margin-left: -14px;
}

.timeline-item-tfi:after,
.timeline-item-tfi:before,
.timeline-stacked-down-tfi .timeline-item-tfi:before {
  display: inline-block;
  font-weight: 900;
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  speak: none;
}

.timeline-item-tfi:before {
  content: '\F0D8';
  color: #f6f6f6;
}

.timeline-item-tfi:after {
  content: '\f140';
  top: -39px;
  background: #f6f6f6;
}

.timeline-item-tfi.highlight-tfi:after,
.timeline-item-tfi.marker-highlight-tfi:after,
.timeline-item-tfi.tag-featured-tfi:after {
  color: #65b1a5;
}

.timeline-item-tfi.overlap-push-large {
  margin-top: 120px;
}

.timeline-item-tfi.overlap-push-medium {
  margin-top: 60px;
}

.timeline-item-tfi.overlap-push-small {
  margin-top: 30px;
}

.timeline-stacked-down-tfi .timeline-item-tfi:first-child {
  margin-top: 40px;
}

.timeline-stacked-down-tfi .timeline-item-tfi:last-child {
  margin-bottom: 60px;
}

.timeline-stacked-down .timeline-item-tfi:after,
.timeline-stacked-down .timeline-item-tfi:before {
  bottom: -20px;
  top: auto;
}

.timeline-stacked-down .timeline-item-tfi:before {
  content: '\F0D7';
}

.timeline-stacked-down .timeline-item-tfi:after {
  bottom: -40px;
  top: auto;
}

.timeline-item-date-tfi {
  font-weight: 600;
  color: #666;
}

.timeline-item-title-tfi {
  margin-top: 0;
}

.avatar-title {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--bs-text-primary);
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: bold;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}

@media (min-width: 576px) {
  .timeline-breaker-tfi.timeline-breaker-bottom-tfi,
  .timeline-breaker-tfi.timeline-breaker-middle-tfi {
    top: 40px;
  }
  .timeline-item-tfi {
    float: left;
    width: 48%;
    padding: 15px;
    margin-top: 40px;
    right: 30px;
    margin-left: 2%;
    clear: left;
  }
  .timeline-item-tfi:after,
  .timeline-item-tfi:before {
    top: 10%;
    bottom: auto;
    right: -20px;
    left: auto;
    position: absolute;
    pointer-events: none;
    margin: 0;
    display: block;
    font-size: 30px;
    height: 30px;
    line-height: 30px;
    width: 30px;
    text-align: center;
  }
  .timeline-item-tfi.even:before,
  .timeline-item-tfi.right:before,
  .timeline-item-tfi:before {
    display: inline-block;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-style: normal;
    speak: none;
  }
  .timeline-item-tfi:before {
    content: '\F0DA';
  }
  .timeline-item-tfi:after {
    right: -46px;
  }
  .timeline-item-tfi.even,
  .timeline-item-tfi.right {
    float: right;
    clear: right;
    left: 30px;
    right: 0;
    margin-right: 2%;
    margin-left: 0;
    margin-top: 100px;
  }
  .timeline-item-tfi.even:after,
  .timeline-item-tfi.even:before,
  .timeline-item-tfi.right:after,
  .timeline-item-tfi.right:before {
    left: -20px;
    top: 10%;
  }
  .timeline-item-tfi.even:before,
  .timeline-item-tfi.right:before {
    content: '\F0D9';
  }
  .timeline-item-tfi.even:after,
  .timeline-item-tfi.right:after {
    left: -46px;
  }
  .timeline-item-tfi.overlap-off {
    margin-top: 0;
  }
  .timeline-item-tfi.overlap-pull-large {
    margin-top: -120px;
  }
  .timeline-item-tfi.overlap-pull-small {
    margin-top: -30px;
  }
  .timeline-stacked,
  .timeline-tfi.timeline-stacked-tfi {
    padding-left: 0;
    padding-right: 0;
  }
  .timeline-stacked-tfi .timeline-item-tfi,
  .timeline-stacked-tfi .timeline-item-tfi.even,
  .timeline-stacked-tfi .timeline-item-tfi.right,
  .timeline-tfi.timeline-stacked-tfi .timeline-item-tfi,
  .timeline-tfi.timeline-stacked-tfi .timeline-item-tfi.even,
  .timeline-tfi.timeline-stacked-tfi .timeline-item-tfi.right {
    float: none;
    left: auto;
    right: auto;
    width: 100%;
    padding: 15px;
    margin: 80px auto 0;
    background: #f6f6f6;
    border-radius: 2px;
    position: relative;
    border: 1px solid #f2f2f2;
    border-bottom: 3px solid #55a79a;
    text-align: left;
  }
  .timeline-stacked-tfi .timeline-item-tfi.even::after,
  .timeline-stacked-tfi .timeline-item-tfi.right::after,
  .timeline-stacked-tfi .timeline-item-tfi::after,
  .timeline-tfi.timeline-stacked-tfi .timeline-item-tfi.even::after,
  .timeline-tfi.timeline-stacked-tfi .timeline-item-tfi.right::after,
  .timeline-tfi.timeline-stacked-tfi .timeline-item-tfi::after {
    display: block;
    clear: both;
    content: '';
  }
  .timeline-stacked-tfi .timeline-item-tfi.even:after,
  .timeline-stacked-tfi .timeline-item-tfi.even:before,
  .timeline-stacked-tfi .timeline-item-tfi.right:after,
  .timeline-stacked-tfi .timeline-item-tfi.right:before,
  .timeline-stacked-tfi .timeline-item-tfi:after,
  .timeline-stacked-tfi .timeline-item-tfi:before,
  .timeline-tfi.timeline-stacked-tfi .timeline-item-tfi.even:after,
  .timeline-tfi.timeline-stacked-tfi .timeline-item-tfi.even:before,
  .timeline-tfi.timeline-stacked-tfi .timeline-item-tfi.right:after,
  .timeline-tfi.timeline-stacked-tfi .timeline-item-tfi.right:before,
  .timeline-tfi.timeline-stacked-tfi .timeline-item-tfi:after,
  .timeline-tfi.timeline-stacked-tfi .timeline-item-tfi:before {
    top: -20px;
    right: 50%;
    left: 50%;
    position: absolute;
    pointer-events: none;
    display: block;
    font-size: 30px;
    height: 30px;
    line-height: 30px;
    width: 30px;
    text-align: center;
    margin-top: 0;
    margin-left: -14px;
  }
  .timeline-stacked-tfi .timeline-item-tfi.even:before,
  .timeline-stacked-tfi .timeline-item-tfi.right:before,
  .timeline-stacked-tfi .timeline-item-tfi:before,
  .timeline-tfi.timeline-stacked-tfi .timeline-item-tfi.even:before,
  .timeline-tfi.timeline-stacked-tfi .timeline-item-tfi.right:before,
  .timeline-tfi.timeline-stacked-tfi .timeline-item-tfi:before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-style: normal;
    speak: none;
    display: inline-block;
    content: '\F0D8';
    color: #f6f6f6;
  }
  .timeline-stacked-tfi .timeline-item-tfi.even:after,
  .timeline-stacked-tfi .timeline-item-tfi.right:after,
  .timeline-stacked-tfi .timeline-item-tfi:after,
  .timeline-tfi.timeline-stacked-tfi .timeline-item-tfi.even:after,
  .timeline-tfi.timeline-stacked-tfi .timeline-item-tfi.right:after,
  .timeline-tfi.timeline-stacked-tfi .timeline-item-tfi:after {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-style: normal;
    speak: none;
    display: inline-block;
    content: '\f140';
    top: -39px;
    background: #fff;
  }
  .timeline-stacked-tfi .timeline-item-tfi.even.highlight:after,
  .timeline-stacked-tfi .timeline-item-tfi.even.marker-highlight:after,
  .timeline-stacked-tfi .timeline-item-tfi.even.tag-featured:after,
  .timeline-stacked-tfi .timeline-item-tfi.highlight:after,
  .timeline-stacked-tfi .timeline-item-tfi.marker-highlight:after,
  .timeline-stacked-tfi .timeline-item-tfi.right.highlight:after,
  .timeline-stacked-tfi .timeline-item-tfi.right.marker-highlight:after,
  .timeline-stacked-tfi .timeline-item-tfi.right.tag-featured:after,
  .timeline-stacked-tfi .timeline-item-tfi.tag-featured:after,
  .timeline.timeline-stacked-tfi .timeline-item-tfi.even.highlight:after,
  .timeline.timeline-stacked-tfi .timeline-item-tfi.even.marker-highlight:after,
  .timeline.timeline-stacked-tfi .timeline-item-tfi.even.tag-featured:after,
  .timeline.timeline-stacked-tfi .timeline-item-tfi.highlight:after,
  .timeline.timeline-stacked-tfi .timeline-item-tfi.marker-highlight:after,
  .timeline.timeline-stacked-tfi .timeline-item-tfi.right.highlight:after,
  .timeline.timeline-stacked-tfi .timeline-item-tfi.right.marker-highlight:after,
  .timeline.timeline-stacked-tfi .timeline-item-tfi.right.tag-featured:after,
  .timeline.timeline-stacked-tfi .timeline-item-tfi.tag-featured:after {
    color: #65b1a5;
  }
  .timeline-stacked-tfi .timeline-item-tfi.even.overlap-push-large,
  .timeline-stacked-tfi .timeline-item-tfi.overlap-push-large,
  .timeline-stacked-tfi .timeline-item-tfi.right.overlap-push-large,
  .timeline-tfi.timeline-stacked-tfi .timeline-item-tfi.even.overlap-push-large,
  .timeline-tfi.timeline-stacked-tfi .timeline-item-tfi.overlap-push-large,
  .timeline-tfi.timeline-stacked-tfi .timeline-item-tfi.right.overlap-push-large {
    margin-top: 120px;
  }
  .timeline-stacked-tfi .timeline-item-tfi.even.overlap-push-medium,
  .timeline-stacked-tfi .timeline-item-tfi.overlap-push-medium,
  .timeline-stacked-tfi .timeline-item-tfi.right.overlap-push-medium,
  .timeline-tfi.timeline-stacked-tfi .timeline-item-tfi.even.overlap-push-medium,
  .timeline-tfi.timeline-stacked-tfi .timeline-item-tfi.overlap-push-medium,
  .timeline-tfi.timeline-stacked-tfi .timeline-item-tfi.right.overlap-push-medium {
    margin-top: 60px;
  }
  .timeline-stacked-tfi .timeline-item-tfi.even.overlap-push-small,
  .timeline-stacked-tfi .timeline-item-tfi.overlap-push-small,
  .timeline-stacked-tfi .timeline-item-tfi.right.overlap-push-small,
  .timeline-tfi.timeline-stacked-tfi .timeline-item-tfi.even.overlap-push-small,
  .timeline-tfi.timeline-stacked-tfi .timeline-item-tfi.overlap-push-small,
  .timeline-tfi.timeline-stacked-tfi .timeline-item-tfi.right.overlap-push-small {
    margin-top: 30px;
  }
  .timeline-stacked-tfi.timeline-stacked-down .timeline-item-tfi:first-child,
  .timeline-tfi.timeline-stacked-tfi.timeline-stacked-down .timeline-item-tfi:first-child {
    margin-top: 40px;
  }
  .timeline-stacked-tfi.timeline-stacked-down .timeline-item:last-child,
  .timeline.timeline-stacked.timeline-stacked-down .timeline-item:last-child {
    margin-bottom: 60px;
  }
  .timeline-stacked.timeline-stacked-down .timeline-item-tfi:after,
  .timeline-stacked.timeline-stacked-down .timeline-item-tfi:before,
  .timeline-tfi.timeline-stacked-tfi.timeline-stacked-down .timeline-item-tfi:after,
  .timeline-tfi.timeline-stacked-tfi.timeline-stacked-down .timeline-item-tfi:before {
    bottom: -20px;
    top: auto;
  }
  .timeline-stacked-tfi.timeline-stacked-down .timeline-item-tfi:before,
  .timeline-tfi.timeline-stacked-tfi.timeline-stacked-down .timeline-item-tfi:before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-style: normal;
    speak: none;
    display: inline-block;
    content: '\F0D7';
  }
  .timeline-stacked-tfi.timeline-stacked-down .timeline-item-tfi:after,
  .timeline-tfi.timeline-stacked-tfi.timeline-stacked-down .timeline-item-tfi:after {
    bottom: -40px;
    top: auto;
  }
  .timeline-stacked-tfi.timeline-stacked-down .timeline-item-tfi.even.last,
  .timeline-stacked-tfi.timeline-stacked-down .timeline-item-tfi.last,
  .timeline-stacked-tfi.timeline-stacked-down .timeline-item-tfi.right.last,
  .timeline-tfi.timeline-stacked-tfi.timeline-stacked-down .timeline-item-tfi.even.last,
  .timeline-tfi.timeline-stacked-tfi.timeline-stacked-down .timeline-item-tfi.last,
  .timeline-tfi.timeline-stacked-tfi.timeline-stacked-down .timeline-item-tfi.right.last {
    margin-bottom: 40px;
  }
  .timeline-stacked-tfi .timeline-breaker-tfi.timeline-breaker-bottom,
  .timeline-stacked-tfi .timeline-breaker-tfi.timeline-breaker-middle,
  .timeline-tfi.timeline-stacked-tfi .timeline-breaker-tfi.timeline-breaker-bottom,
  .timeline-tfi.timeline-stacked-tfi .timeline-breaker-tfi.timeline-breaker-middle {
    top: auto;
  }
}

.timeline-left-tfi,
.timeline-tfi.timeline-left-tfi {
  background-position: left top;
  margin-left: 20px;
  padding-bottom: 0;
  text-align: left;
}

.timeline-left-tfi .timeline-marker-tfi,
.timeline.timeline-left .timeline-marker {
  margin-left: -5px;
  margin-right: 0;
}

.timeline-left-tfi .timeline-marker-tfi:after,
.timeline-left-tfi .timeline-marker-tfi:before,
.timeline-tfi.timeline-left-tfi .timeline-marker-tfi:after,
.timeline-tfi.timeline-left-tfi .timeline-marker-tfi:before {
  margin-left: 0;
}

.timeline-left-tfi .timeline-breaker-tfi,
.timeline-tfi.timeline-left-tfi .timeline-breaker-tfi {
  margin-left: -20px;
}

.timeline-left-tfi .timeline-breaker-tfi:after,
.timeline-left-tfi .timeline-breaker-tfi:before,
.timeline-tfi.timeline-left-tfi .timeline-breaker-tfi:after,
.timeline-tfi.timeline-left-tfi .timeline-breaker-tfi:before {
  left: 20px;
}

.timeline-left-tfi .timeline-item-tfi,
.timeline-left-tfi .timeline-item-tfi.even,
.timeline-left-tfi .timeline-item-tfi.right,
.timeline-tfi.timeline-left-tfi .timeline-item-tfi,
.timeline-tfi.timeline-left-tfi .timeline-item-tfi.even,
.timeline-tfi.timeline-left-tfi .timeline-item-tfi.right {
  float: none;
  clear: both;
  width: 92%;
  margin-left: 25px;
  margin-right: 0;
  margin-top: 40px;
  left: auto;
  right: auto;
}

.timeline-left-tfi .timeline-item-tfi.even:after,
.timeline-left-tfi .timeline-item-tfi.even:before,
.timeline-left-tfi .timeline-item-tfi.right:after,
.timeline-left-tfi .timeline-item-tfi.right:before,
.timeline-left-tfi .timeline-item-tfi:after,
.timeline-left-tfi .timeline-item-tfi:before,
.timeline-tfi.timeline-left-tfi .timeline-item-tfi.even:after,
.timeline-tfi.timeline-left-tfi .timeline-item-tfi.even:before,
.timeline-tfi.timeline-left-tfi .timeline-item-tfi.right:after,
.timeline-tfi.timeline-left-tfi .timeline-item-tfi.right:before,
.timeline-tfi.timeline-left-tfi .timeline-item-tfi:after,
.timeline-tfi.timeline-left-tfi .timeline-item-tfi:before {
  right: auto;
  left: -20px;
  margin-left: 0;
  top: 20px;
}

.timeline-left-tfi .timeline-item-tfi.even:before,
.timeline-left-tfi .timeline-item-tfi.right:before,
.timeline-left-tfi .timeline-item-tfi:before,
.timeline-tfi.timeline-left-tfi .timeline-item-tfi.even:before,
.timeline-tfi.timeline-left-tfi .timeline-item-tfi.right:before,
.timeline-tfi.timeline-left-tfi .timeline-item-tfi:before {
  font-weight: 900;
  font-style: normal;
  speak: none;
  display: inline-block;
  content: '\F0D9';
}

.timeline-left-tfi .timeline-item-tfi.even:after,
.timeline-left-tfi .timeline-item-tfi.right:after,
.timeline-left-tfi .timeline-item-tfi:after,
.timeline-tfi.timeline-left-tfi .timeline-item-tfi.even:after,
.timeline-tfi.timeline-left-tfi .timeline-item-tfi.right:after,
.timeline-tfi.timeline-left-tfi .timeline-item-tfi:after {
  left: -40px;
}

.timeline-left-tfi .pagination,
.timeline-tfi.timeline-left-tfi .pagination {
  background: 0 0;
  padding-left: 1em;
  padding-right: 1em;
}

.timeline-left-tfi .timeline-breaker-tfi.timeline-breaker-bottom,
.timeline-left-tfi .timeline-breaker-tfi.timeline-breaker-middle,
.timeline-tfi.timeline-left-tfi .timeline-breaker-tfi.timeline-breaker-bottom,
.timeline-tfi.timeline-left-tfi .timeline-breaker-tfi.timeline-breaker-middle {
  top: auto;
  margin-bottom: 0;
}

.timeline-right-tfi,
.timeline-tfi.timeline-right-tfi {
  background-position: right top;
  margin-right: 20px;
  text-align: right;
}

.timeline-right-tfi .timeline-marker-tfi,
.timeline-tfi.timeline-right-tfi .timeline-marker-tfi {
  margin-left: 0;
  margin-right: -5px;
  float: right;
}

.timeline-right-tfi .timeline-breaker-tfi,
.timeline-tfi.timeline-right-tfi .timeline-breaker-tfi {
  margin-left: 0;
  margin-right: -20px;
  float: right;
}

.timeline-right-tfi .timeline-breaker-tfi:after,
.timeline-right-tfi .timeline-breaker-tfi:before,
.timeline-tfi.timeline-right-tfi .timeline-breaker-tfi:after,
.timeline-tfi.timeline-right-tfi .timeline-breaker-tfi:before {
  right: 12px;
  left: auto;
}

.timeline-right-tfi .timeline-item-tfi,
.timeline-right-tfi .timeline-item-tfi.even,
.timeline-right-tfi .timeline-item-tfi.right,
.timeline-tfi.timeline-right-tfi .timeline-item-tfi,
.timeline-tfi.timeline-right-tfi .timeline-item-tfi.even,
.timeline-tfi.timeline-right-tfi .timeline-item-tfi.right {
  float: right;
  clear: both;
  width: 92%;
  margin-left: 0;
  margin-right: 25px;
  margin-top: 40px;
  left: auto;
  right: 0;
}

.timeline-right-tfi .timeline-item-tfi.even:after,
.timeline-right-tfi .timeline-item-tfi.even:before,
.timeline-right-tfi .timeline-item-tfi.right:after,
.timeline-right-tfi .timeline-item-tfi.right:before,
.timeline-right-tfi .timeline-item-tfi:after,
.timeline-right-tfi .timeline-item-tfi:before,
.timeline-tfi.timeline-right-tfi .timeline-item-tfi.even:after,
.timeline-tfi.timeline-right-tfi .timeline-item-tfi.even:before,
.timeline-tfi.timeline-right-tfi .timeline-item-tfi.right:after,
.timeline-tfi.timeline-right-tfi .timeline-item-tfi.right:before,
.timeline-tfi.timeline-right-tfi .timeline-item-tfi:after,
.timeline-tfi.timeline-right-tfi .timeline-item-tfi:before {
  left: auto;
  right: -20px;
  margin-right: 0;
  top: 20px;
}

.timeline-right-tfi .timeline-item-tfi.even:before,
.timeline-right-tfi .timeline-item-tfi.right:before,
.timeline-right-tfi .timeline-item-tfi:before,
.timeline-tfi.timeline-right-tfi .timeline-item-tfi.even:before,
.timeline-tfi.timeline-right-tfi .timeline-item-tfi.right:before,
.timeline-tfi.timeline-right-tfi .timeline-item-tfi:before {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-style: normal;
  speak: none;
  display: inline-block;
  content: '\F0DA';
}

.timeline-right-tfi .timeline-item-tfi.even:after,
.timeline-right-tfi .timeline-item-tfi.right:after,
.timeline-right-tfi .timeline-item-tfi:after,
.timeline-tfi.timeline-right-tfi .timeline-item-tfi.even:after,
.timeline-tfi.timeline-right-tfi .timeline-item-tfi.right:after,
.timeline-tfi.timeline-right-tfi .timeline-item-tfi:after {
  right: -40px;
  left: auto;
}

.timeline-right-tfi .pagination,
.timeline-tfi.timeline-right-tfi .pagination {
  background: 0 0;
  padding-left: 1em;
  padding-right: 1em;
}

.timeline-right-tfi .timeline-breaker-tfi.timeline-breaker-bottom,
.timeline-right-tfi .timeline-breaker-tfi.timeline-breaker-middle,
.timeline-tfi.timeline-right-tfi .timeline-breaker-tfi.timeline-breaker-bottom,
.timeline-tfi.timeline-right-tfi .timeline-breaker-tfi.timeline-breaker-middle {
  top: auto;
  margin-bottom: 0;
}

.timeline-mini .timeline-item {
  padding: 0.5em !important;
  margin-top: 50px !important;
}

@media (min-width: 576px) {
  .timeline-tfi.timeline-left-tfi .timeline-item-tfi,
  .timeline-tfi.timeline-left-tfi .timeline-item-tfi.even,
  .timeline-tfi.timeline-left-tfi .timeline-item-tfi.right,
  .timeline-tfi.timeline-right-tfi .timeline-item-tfi,
  .timeline-tfi.timeline-right-tfi .timeline-item-tfi.even,
  .timeline-tfi.timeline-right-tfi .timeline-item-tfi.right {
    width: 96%;
  }
}

.h-full-quill .ql-container,
.ql-editor {
  min-height: 130px;
}

td .tag-insurance-list{
  background-color: #000;
  border-radius: 50px;
  padding: 3px 6px;
  display: inline-block;
  font-size: 12px;
  color: white;
  font-weight: bold;
}

.btn-very-smaller {
  padding: 3px 3px 4px 8px !important;
}

.btn-very-smaller:hover i {
  color: red !important;
  font-weight: bold !important;
}

.div-bi-trash h4 {
  margin-bottom: 0px;
  margin-right: 25px;
  font-size: 2em;
}

.div-bi-trash {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  background-color: #f9f9f9;
} 

.table:not(.table-bordered) tr, .table:not(.table-bordered) th, .table:not(.table-bordered) td{
  vertical-align: middle;
}
.btn-actions-group i.bi {
  font-size: 1.5em;
  margin-right: 5px;
}

.list-user-assigned,
.list-user-available {
  border: 1px solid #ccc;
  margin: 10px 10px;
  padding: 3px;
  background-color: #f1f1f1;
}
.user-assigned,
.user-available {
  height: 25px;
  border: 1px solid #ccc;
  padding: 0px 5px;
  margin: 1px;
  border-radius: 4px;
}
.list-user-assigned .title,
.list-user-available .title {
  
  margin: -11px -10px 5px -10px;
  padding: 6px 10px;
  border-top: #ccc;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  font-weight: bold;
  
}
.list-user-available .title {
  background: gray;
  color: #fff;
}
.list-user-assigned .title{
  background: rgb(77, 95, 235);
  color: #fff;
}
.reassign-leads {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.total-leads-assign-to {
  font-size: 1.0em;
  font-style: italic;
  padding: 2px 0px 0px 10px;
}

div.traffic-red,
div.traffic-orange,
div.traffic-green{
  border-bottom: 5px solid;
  margin: 10px -2px;
  border-radius: 4px;
}

div.traffic-red{
  border-bottom-color: var(--bs-danger);
}
div.traffic-orange{
  border-bottom-color: var(--bs-warning);
}
div.traffic-green{
  border-bottom-color: var(--bs-success);
}

td.traffic-red,
td.traffic-orange,
td.traffic-green{
  border-left: 5px solid !important;
  padding-left: 10px !important;
  border-radius: 4px;
}

td.traffic-red{
  border-left-color: var(--bs-danger) !important;
}
td.traffic-orange{
  border-left-color: var(--bs-warning) !important;
}
td.traffic-green{
  border-left-color: var(--bs-success) !important;
}

td.td-lead-declined{
  padding-left: 13px !important;
}

.table-scrollable.table-container {
  max-height: 300px;
  overflow-y: auto;
  border-radius: 5px;
}

.table-scrollable .table-scroll thead {
  border-top-color: var(--bs-table-border-color);
  position: sticky;
  top: -1px;
  background-color: white; /* o el color que tengas de fondo */
  z-index: 1;
}

.list-leads .form-check-input {
  width: 1.50em;
  height: 1.50em;
}
.list-leads th.td-lead-checbox,
.list-leads td.td-lead-checbox {
  text-align: center;
}
th.td-lead-checbox {
  padding-left: 10px !important;
}
.td-lead-checbox {
  width: 30px !important;
}
.reassign-to-stage,
.reassign-to-user{
  width: 45% !important;
}
.reassign-leads.reassign-from {
  padding: 11px;
  border: 1px solid var(--bs-table-border-color);;
  background-color: #f1f1f1;
  border-radius: 5px;
}
.reassign-leads.reassign-to {
  padding: 11px;
  border: 1px solid var(--bs-table-border-color);;
  background-color: aliceblue;
  border-radius: 5px;
}
.reassign-leads ul.pagination span {
  color: #333 !important;
}
span.filters-applied {
  font-size: 10pt;
  font-style: italic;
  font-weight: normal;
}
.modal-reassing-leads .modal-header {
  padding: 15px 25px;
}
.modal-reassing-leads .modal-body {
  padding: 20px 20px;
}